 .wrapper{
    position: relative;
    top: 0;
    height: 100vh;
}
.bootstrap-navbar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 260px;
    display: block;
    z-index: 1;
    color: #fff;
    font-weight: 200;
    background-size: cover;
    background-position: center center;
}
.sidebar,
.off-canvas-sidebar{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    @include shadow-big();


    .sidebar-wrapper{
        position: relative;
        height: calc(100vh - 75px);
        overflow: auto;
        width: 260px;
        z-index: 4;
        //background-color: $white-color;
    }
    .logo-img{
        width: 30px;
        display: inline-block;
        max-height: 30px;
        margin-left: 10px;
        margin-right: 15px;

        img{
            width: 35px;
            top: 16px;
            position: absolute;
        }
    }

    .logo-tim{
        border-radius: 50%;
        border: 1px solid #333;
        display: block;
        height: 61px;
        width: 61px;
        float: left;
        overflow: hidden;

        img{
            width: 60px;
            height: 60px;
        }
    }



    .nav{
        margin-top: 20px;

        li{
            > a{
                margin: 10px 15px;
                border-radius: $border-radius-base;
                color: $black-color;
                @extend .animation-transition-general;
            }

            &:hover > a{
                background: rgba(200, 200, 200, 0.2);
                color: $black-color;
            }

            &.active > a{
                color: #FFFFFF;

                i{
                    color: #FFFFFF;
                }
            }
        }

        p{
            margin: 0;
            line-height: 30px;
            font-size: 14px;
            // font-weight: 600;
            // text-transform: uppercase;
        }

        i{
            font-size: 24px;
            float: left;
            margin-right: 15px;
            line-height: 30px;
            width: 30px;
            text-align: center;
            color: #a9afbb;
        }
    }

    .sidebar-background{
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;

        &:after{
            position: absolute;
            z-index: 3;
            width: 100%;
            height: 100%;
            content: "";
            display: block;
            background: #FFFFFF;
            opacity: .93;
        }
    }

    .logo{
        position: relative;
        padding: $padding-base $padding-base;
        z-index: 4;

        &:after{
            content: '';
            position: absolute;
            bottom: 0;
            right: 10%;
            height: 1px;
            width: 80%;
            background-color: rgba(180,180,180, .3);

        }

        p{
            float: left;
            font-size: 20px;
            margin: 10px 10px;
            color: $white-color;
            line-height: 20px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        }

        .simple-text{
            text-transform: uppercase;
            padding: $padding-small-vertical $padding-zero;
            display: block;
            font-size: $font-size-large;
            color: $black-color;
            text-align: left;
            font-weight: $font-weight-default;
            line-height: 30px;
        }
    }

    .logo-tim{
        border-radius: 50%;
        border: 1px solid #333;
        display: block;
        height: 61px;
        width: 61px;
        float: left;
        overflow: hidden;

        img{
            width: 60px;
            height: 60px;
        }
    }

    &:after,
    &:before{
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
    }

    &:before{
        opacity: .33;
        // background: #000000;
    }

    &:after{
    //    @include set-background-color-button($font-background-light-grey, $background-lighter-grey);
       z-index: 3;
       opacity: 1;
    }

    &[data-image]:after,
    &.has-image:after{
        opacity: .77;
    }
    &[data-color="voix"]{
        @include set-background-color-button($brand-voix);
    }
    &[data-color="blue"]{
        @include set-background-color-button($brand-info);
    }
    &[data-color="green"]{
        @include set-background-color-button($brand-success);
    }
    &[data-color="orange"]{
        @include set-background-color-button($brand-warning);
    }
    &[data-color="red"]{
        @include set-background-color-button($brand-danger);
    }
    &[data-color="purple"]{
        @include set-background-color-button($brand-primary);
    }
}

.off-canvas-sidebar{
    .nav {
        > li > a,
        > li > a:hover{
            color: $white-color;
        }

        > li > a:focus{
            background: rgba(200, 200, 200, 0.2);
        }
    }
}


.main-panel{
    //background: rgba(203,203,210,.15);
    position: relative;
    // z-index: 2;
    float: right;
    min-height: 100%;
    width: $sidebar-width;

    .main-content{
        margin-top: 30px;
        padding: 5px 15px;
        min-height: calc(100% - 123px);
    }
    div{
        .footer{
            border-top: 1px solid #e7e7e7;
        }
    }
    > .navbar{
        margin-bottom: 0;
    }
}
.bootstrap-navbar .nav{
    margin-top: 20px;
    float: none;
}
.main-panel{
    position: relative;
    height: 100%;
}
.sidebar,
.main-panel{
    -webkit-transition-property: top,bottom,width;
    transition-property: top,bottom,width;
    -webkit-transition-duration: .2s,.2s,.35s;
    transition-duration: .2s,.2s,.35s;
    -webkit-transition-timing-function: linear,linear,ease;
    transition-timing-function: linear,linear,ease;
    -webkit-overflow-scrolling: touch;
}
