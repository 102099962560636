.dropdown-menu {
    border: 0;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);

    .divider {
        background-color: rgba(0, 0, 0, .12);
    }


    li > a{
        font-size: $mdb-dropdown-font-size;
        padding: 10px 20px;
        margin: 0 5px;
        border-radius: $border-radius-small;
        @include transition($fast-transition-time, $transition-linear);

        &:hover,
        &:focus {
          @include shadow-8dp();

        }
    }

    &.dropdown-with-icons{
        li > a{
            padding: 12px 20px 12px 12px;

            .material-icons{
                vertical-align: middle;
                font-size: 24px;
                position: relative;
                margin-top: -4px;
                top: 1px;
                margin-right: 12px;
                opacity: .5;
            }
        }
    }

    li {
        position: relative;
        a:hover,
        a:focus,
        a:active {
            background-color: $blue-600;
            color: #FFFFFF;
        }
    }

    .divider{
        margin: 5px 0;
    }

    .navbar &,
    .navbar.navbar-default &{
        li{
            a:hover,
            a:focus,
            a:active {
                background-color: $blue-600;
                color: #FFFFFF;
                @include shadow-big-color($blue-600);
            }
        }
    }

}
