.table{
   > thead > tr > th{
       border-bottom-width: 1px;
       font-size: $font-size-h6;
       font-weight: $font-weight-light;
   }

   .radio,
   .checkbox{
       margin-top: 0;
       margin-bottom: 0;
       margin-left: 10px;
       padding: 0;
       width: 15px;

       .icons{
           position: relative;
       }
   }
   > thead > tr > th,
   > tbody > tr > th,
   > tfoot > tr > th,
   > thead > tr > td,
   > tbody > tr > td,
   > tfoot > tr > td{
       padding: 12px 8px;
       vertical-align: middle;
   }

   > thead > tr > th{
       padding-bottom: 4px;
   }

   .td-actions{
        display: flex;

        .btn{
           margin: 0px;
           padding: 5px;
        }
    }
    > tbody > tr{
        position: relative;
    }
}
