/* You can add global styles to this file, and also import other style files */
body {
  font-size: 14px !important;
}
.padding-30 {
  padding-right: 30px;
  padding-left: 30px;
}
body .text-uppercase {
  text-transform: uppercase;
}
.body {
  font-size: 12px;
}
.secondary-color {
  color: #ff6d00 !important;
}
.footer {
  position: relative;
  display: inline-block;
  width: 100%;
}

.previous {
  position: absolute !important;
  left: 30px;
  top: 0px;
  margin: 10px 1px;
  padding-left: 30px !important;
}

.w-100 {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.p-0 {
  padding: 0 !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


.owl-dt-container {
  font-size: 16px !important;
}
.radio-choose {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}

.txt-green {
  color: #28a745;
}

.txt-red {
  color: #dc3545;
}

.radio-choose .mat-radio-label-content {
  color: #444;
  font-size: 18px;
}

.selected-number {
  padding: 0px 37px 15px !important;
  font-size: 20px;
}

.selected-number span {
  font-size: 22px;
  font-weight: 500;
}
.back-btn {
  position: absolute;
  top: 6px;
}
.back-left {
  left: 6px;
}
.after-btn {
  right: 9px;
  top: 11px;
}

.padding-bottom-4 .card-login {
  padding-bottom: 4px;
}
.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.justify-content-end {
  justify-content: end;
}
.justify-content-space-bw {
  justify-content: space-between;
}
.mt-2 {
  margin-top: 10px !important;
}

.mb-2 {
  margin-bottom: 10px !important;
}

.p-2 {
  padding: 5px !important;
}
.pb-2 {
  padding-bottom: 5px !important;
}

.success-snackbar {
  background: #28a745;
  font-weight: bold;
}

.blue-snackbar {
  background: #1d81ef;
  font-weight: bold;
}

.danger-snackbar {
  background-color: #dc3545;
  font-weight: bold;
}

.warning-snackbar {
  background-color: #ffc107;
  font-weight: bold;
  color: black;
}

.mat-simple-snackbar-action {
  color: white;
}
/* ::ng-deep .pac-container {
  z-index: 20000 !important;
  position: absolute;
} */

.mat-checkbox-layout {
  font-size: 25px;
}

.navbar {
  padding: 0px 0;
}

.navbar .navbar-nav > li > a {
  color: inherit;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
}
.navbar-nav, .navbar-nav:hover{
  color: unset !important;
}

/* @media (min-width: 768px) {
  .navbar-nav {
      float: left;
      margin: 0;
  }
} */
.align-left {
  text-align: left !important;
}

.align-right {
  text-align: right !important;
}

.align-center {
  text-align: center !important;
}

.mt5 {
  margin-top: 5px !important;
}
.table-heading {
  font-weight: bold !important;
}
.error-message {
  color: red;
}
.btn-action-buttons {
  background-color: #1e88e5;
  color: #ffffff;
}
.btn-action-buttons-icon {
  font-size: 12px !important;
  margin-right: 6px;
  margin-top: -4px !important;
}
.check_uncheck_common {
  text-align: center;
  width: 5%;
}

.backdropBackground {
  filter: blur(20px);
  background-color: grey;
  opacity: 0.1 !important;
}
.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  height: 100px;
}

.asterisk-mask-input {
  -webkit-text-security: disc;
}

.loyalty-offer-icon {
  color: #ffd700;
  display: flex !important;
  justify-content: center;
}

.loyalty-offer-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.promo-loyalty-offer {
  border: 1px solid gold !important;
}

.promo-coupon-loyalty-offer {
  display: flex;
  align-items: center;
  width: 100%;
}

.promo-coupon-loyalty-offer:hover {
  background: rgba(0, 0, 0, 0.04);
}

.promo-coupon-loyalty-offer .mat-option {
  background: unset !important;
  max-width: 90% !important;
  padding-right: 6px;
}

.ngx-toastr {
  width: 470px !important;
}

.ngx-toastr .auto-apply-toast {
  font-size: 22px;
  line-height: 32px;
}


/* bootstrap v3 missing style */

/* navbar top */

.navbar.navbar-transparent {
  background-color: transparent;
  box-shadow: none;
  border-bottom: 0;
}


.navbar {
  margin-bottom: 0px;
  padding: 0px 0;
  border: 0;
  border-radius: 3px;
  min-height: 50px;
  display: unset;
}

.navbar-absolute {
  position: relative;
  padding-top: 0px;
  z-index: 500 !important;
}

.navbar, .navbar.navbar-default {
  background-color: #FFFFFF;
  color: #555555;
}

.animation-transition-fast, .navbar {
  transition: all 150ms ease 0s;
}

/* end navbar */

.sidebar .sidebar-wrapper, .off-canvas-sidebar .sidebar-wrapper {
  position: relative;
  height: calc(100vh - 75px);
  overflow: auto;
  width: 260px;
  z-index: 4;
}

.sidebar .sidebar-wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example.sidebar .sidebar-wrapper {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, .87);

}
/* .mat-drawer-container .mat-drawer-content{
  overflow: none !important;
} */


.sidebar .nav, .off-canvas-sidebar .nav {
  margin-top: 30px;
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: block;
}

.nav > li {
  position: relative;
  display: block;
}

.sidebar .nav li.active>a,
.off-canvas-sidebar .nav li.active>a {
  color: #FFFFFF;
}

.sidebar .nav p, .off-canvas-sidebar .nav p {
  margin: 0;
  line-height: 30px;
  font-size: 14px;
}

.animation-transition-general,
.sidebar .nav li>a,
.off-canvas-sidebar .nav li>a {
  transition: all 300ms linear;
}

.sidebar .nav li>a,
.off-canvas-sidebar .nav li>a {
  margin: 10px 15px;
  border-radius: 3px;
  color: #3C4858;
}

.nav>li>a {
  /* position: relative; */
  display: block;
  padding: 10px 15px;
}

a {
  color: #337ab7;
  text-decoration: none;
}


.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.mobile-customer-search {
  display: flex;
  align-items: center;
}
.mb-30 {
  margin-bottom: 20px;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}

.search-btn-margin{
  margin-right: 8px;
}

strong {
  font-size: 14px;
  font-weight: 700;
}
.card .card-header .title {
  color: #FFFFFF;
}
.card .title {
  margin-top: 0;
  margin-bottom: 5px;
}

.card {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 25px 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  border: 0;
}
.card .card-content {
  padding: 15px 20px;
}

@media (min-width: 992px) {
  .col-md-12 {
      width: 100%;
  }
}

@media (min-width: 992px) {
  .card form [class*=col-]:first-child {
    padding-left: 15px;
}
  .card form [class*=col-]:last-child {
      padding-right: 15px;
  }
  .card form [class*=col-] {
    padding: 6px;
}
}

.mat-form-field {
  display: inline-block;
  position: relative;
  text-align: left;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-wrapper {
  position: relative;
}
.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-flex {
  display: inline-flex;
  align-items: baseline;
  box-sizing: border-box;
  width: 100%;
}

.mat-form-field-infix {
  display: block;
  position: relative;
  flex: auto;
  min-width: 0;
  width: 180px;
}

.mat-form-field-infix {
  padding: .5em 0;
  border-top: .84375em solid rgba(0, 0, 0, 0);
}

.drop-menu-list {
  border-radius: 3px;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: -7px 0 0px;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
}

.drop-menu-list li > a {
  font-size: 13px;
  padding: 10px 10px;
    margin: 0 5px;
    border-radius: 2px;
    transition: all 150ms linear;
    display: block;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
}

.drop-menu-list li a:hover {
  background-color: #1e88e5;
  color: #FFFFFF;
  box-shadow: 0 12px 20px -10px rgba(30, 136, 229, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(30, 136, 229, 0.2);
}

.menu-btn-header { 
  font-weight: normal !important;
  background-color: transparent !important;
  padding: 0px !important;
  border: 2px solid ;
  font-size: 12px !important;
}

.navbar-matmenu {
  position: absolute !important;
  right: 0px;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  align-items: center;
  width: 100%;
}

.input-group .input-group-addon {
  border: 0;
  background: transparent;
  padding: 6px 15px 0px;
}

.card .card-content {
  padding: 15px 20px;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width)* -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 86%;
}
.form-group {
  padding-bottom: 7px;
  margin: 27px 0 0 0;
  position: relative;
}


@media screen and (max-width: 1500px) {
  .promo-code {
    align-self: flex-end;
  }
}

@media screen and (max-width: 991px) {
  .invisible-profile-bar {
    visibility: hidden !important;
  }
}

.up-selling-icon {
  display: flex;
  justify-content: center;
  padding: 11px 0px;
  cursor: pointer;
  margin-right: 12px;
}

.upselling-list-card {
  transition: all 150ms linear !important;
  border-radius: 3px !important;
  max-width: unset !important;
  width: 700px !important;
  padding: 8px 20px 20px 20px !important;
  border: 0 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26) !important;
  background-clip: padding-box !important;

}

.add-to-upsell-item {
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  font-size: 13px;
  border-radius: 2px;
  transition: all 150ms linear;
  max-width: unset !important;

}

.add-to-upsell-item div button:hover {
  background-color: #1e88e5 !important;
  color: #FFFFFF;
  box-shadow: 0 12px 20px -10px rgba(30, 136, 229, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(30, 136, 229, 0.2);
}


.up-selling-icon .notification {
    right: 0;
    left: 16px;
}
.up-selling-icon .notification {
    position: absolute;
    top: 5px;
    border: 1px solid #FFF;
    right: 10px;
    font-size: 9px;
    background: #f44336;
    color: #FFFFFF;
    min-width: 20px;
    padding: 0px 5px;
    height: 20px;
    border-radius: 10px;
    text-align: center;
    line-height: 19px;
    vertical-align: middle;
    display: block;
}


.alert button{
  border: none;
  background-color: transparent;
  color: white;
}

.text-truncate {
  display: inline-block !important;
  max-width: 100% !important;       
  white-space: nowrap !important;
  overflow: hidden !important; 
  text-overflow: ellipsis !important;
  vertical-align: bottom !important;
  padding-top: 12px !important;
  margin-right: 12px !important;
}

.shopping-icon{
  margin-right: 14px !important;
}

.order-bottom-address-card {
  padding: 0px 0px 20px 9px;
  height: 42px;
}

.mat-drawer-content {
  overflow: hidden !important;
  overflow-y: auto !important;
}

b, strong {
  font-weight: 700 !important;
}


.main-panel.ps .ps__rail-x {
  display: none !important;
}


.order-receive-wrpper{
  overflow-y: scroll;
  background-color: #ffffff;
}

@media screen and (max-width: 767px) {
  .topping-col {
      padding-bottom: 10rem !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .topping-col {
      padding-bottom: 3rem !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1100px) {
  .topping-col {
      padding-bottom: 9rem !important;
  }
}
@media screen and (min-width: 1101px) {
  .topping-col {
    padding-bottom: 10rem !important;
  }
}

button, input, optgroup, select, textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}


@media (min-width: 768px) {
  .navbar-header {
      float: left;
  }
}

.navbar-header .logo {
  position: relative;
  padding: 0;
  z-index: 4;
  float: left;
}

@media screen and (max-width: 991px) {
  .selling-btn .upselling-tooltiptext {
    right: 11px !important;
  }
  
  .selling-btn .upselling-tooltiptext::after {
    left: 96% !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1010px) {
  .selling-btn .upselling-tooltiptext {
    right: -70px !important;
  }
  
  .selling-btn .upselling-tooltiptext::after {
    left: 60% !important;
  }
}
@media screen and (min-width: 1100px) and (max-width: 1360px) {
  .selling-btn .upselling-tooltiptext {
    right: 20px !important;
  }
  
  .selling-btn .upselling-tooltiptext::after {
    left: 94% !important;
  }
}
@media screen and (min-width: 1361px) and (max-width: 1450px) {
  .selling-btn .upselling-tooltiptext {
    right: -50px !important;
  }
  
  .selling-btn .upselling-tooltiptext::after {
    left: 67% !important;
  }
}