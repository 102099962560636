.btn,
.navbar .navbar-nav > li > a.btn{
    border: none;
    border-radius: $border-radius-base;
    position: relative;
    padding: 12px 30px;
    margin: 10px 1px;

    font-size: $mdb-btn-font-size-base;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0;

    will-change: box-shadow, transform;
    transition: box-shadow 0.2s $mdb-animation-curve-fast-out-linear-in,
    background-color 0.2s $mdb-animation-curve-default;

    &::-moz-focus-inner {
    border: 0;
    }

    &,
    &.btn-default{
        @include btn-styles($gray-light);
    }

    &.btn-primary{
      @include btn-styles($orange-A700);
    }
    &.btn-info{
      @include btn-styles($brand-info);
    }
    &.btn-success{
      @include btn-styles($brand-success);
    }
    &.btn-warning{
      @include btn-styles($brand-warning);
    }
    &.btn-danger{
      @include btn-styles($brand-danger);
    }
    &.btn-white{
        &,
        &:focus,
        &:hover{
            background-color: $white-color;
            color: $gray-light;
        }
        &.btn-simple{
            color: #FFFFFF;
            background: transparent;
            box-shadow: none;
        }
    }

    // social buttons
    &.btn-facebook {
    	@include social-buttons-color($social-facebook);
    }
    &.btn-twitter {
    	@include social-buttons-color($social-twitter);
    }
    &.btn-pinterest {
    	@include social-buttons-color($social-pinterest);
    }
    &.btn-google {
    	@include social-buttons-color($social-google);
    }
    &.btn-instagram {
    	@include social-buttons-color($social-instagram);
    }

    &:focus,
    &:active,
    &:active:focus{
      outline: 0;
    }

    &.btn-round{
      border-radius: $border-radius-extreme;
    }

    &:not(.btn-just-icon):not(.btn-fab){
      .fa{
          font-size: 18px;
          margin-top: -2px;
          position: relative;
          top: 2px;
      }
    }


    &.btn-fab {
        // see above for color variations
        border-radius: 50%;
        font-size: $mdb-btn-fab-font-size;
        height: $mdb-btn-fab-size;
        margin: auto;
        min-width: $mdb-btn-fab-size;
        width: $mdb-btn-fab-size;
        padding: 0;
        overflow: hidden;
        position: relative;
        line-height: normal;

        .ripple-container {
          border-radius: 50%;
        }

        &.btn-fab-mini,
        .btn-group-sm & {
            height: $mdb-btn-fab-size-mini;
            min-width: $mdb-btn-fab-size-mini;
            width: $mdb-btn-fab-size-mini;

            &.material-icons {
                top: ($mdb-btn-icon-size-mini - $mdb-btn-fab-font-size) / 2;
                left: ($mdb-btn-icon-size-mini - $mdb-btn-fab-font-size) / 2;
            }

            .material-icons{
                font-size: $mdb-btn-icon-size-mini;
            }
        }

        i.material-icons {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-($mdb-btn-fab-font-size / 2), -($mdb-btn-fab-font-size / 2));
          line-height: $mdb-btn-fab-font-size;
          width: $mdb-btn-fab-font-size;
          font-size: $mdb-btn-fab-font-size;
        }
    }

    // Size variations
    &.btn-lg,
    .btn-group-lg & {
        font-size: $mdb-btn-font-size-lg;
        padding: 18px 36px;
    }
    &.btn-sm,
    .btn-group-sm & {
        padding: 5px 20px;
        font-size: $mdb-btn-font-size-sm;
    }
    &.btn-xs,
    .btn-group-xs & {
        padding: 4px 15px;
        font-size: $mdb-btn-font-size-xs;
    }

    &.btn-just-icon{
        font-size: 20px;
        padding: 12px 12px;
        line-height: 1em;

        i{
            width: 20px;
        }
        &.btn-lg{
            font-size: 22px;
            padding: 13px 18px;
        }
    }
}

.btn{
    // Align icons inside buttons with text
    .material-icons{
        vertical-align: middle;
        font-size: $mdb-btn-icon-size-mini;
        top: -1px;
        position: relative;
    }

}

.navbar .navbar-nav > li > {
    a.btn{
        margin-top: 2px;
        margin-bottom: 2px;

        &.btn-fab{
            margin: 5px 2px;
        }
    }
    a:not(.btn){
        .material-icons{
            margin-top: -3px;
            top: 0px;
            position: relative;
            margin-right: 3px;
        }
    }
    .profile-photo{
        margin: 5px 2px;
    }
}

.navbar-default:not(.navbar-transparent) .navbar-nav > li > {
    a.btn{
        &.btn-white.btn-simple{
            color: $gray;
        }
    }
}

// btn-group variations
.btn-group,
.btn-group-vertical {

  position: relative;
  //border-radius: 2px;
  margin: 10px 1px;

  &.open {
    .dropdown-toggle {
      //box-shadow: none;
    }

    & > .dropdown-toggle.btn {
      @include variations(unquote(".btn"), unquote(""), background-color, $mdb-btn-background-color);
    }
  }

  .dropdown-menu {
    border-radius: 0 0 $border-radius-base $border-radius-base;
  }

  &.btn-group-raised {
    @include shadow-2dp();
  }

  & .btn + .btn,
  .btn,
  .btn:active,
  .btn-group {
    margin: 0;
  }
}

.close{
    font-size: inherit;
    color: $white-color;
    opacity: .9;
    text-shadow: none;

    &:hover,
    &:focus{
        opacity: 1;
        color: $white-color;
    }

    i{
        font-size: 20px;
    }
}
